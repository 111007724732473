<template>
  <div
    class="section page-box-cust-change-model"
    :class="isPcMobileClass()"
  >
    <div class="page-title">
      上传变更资料
    </div>
    <div class="page-hint">
      您所提供的信息将得到绝对保护，仅限审核使用
    </div>
    <div class="page-input">
      <cube-form
        :model="model"
        class="form-wrapper"
        @submit="submitHandler"
      >
        <div class="form-content">
          <cube-form-group class="form-group">
            <cube-form-item :field="fields[0]">
              <div
                slot="label"
                class="cube-form-label"
              >
                <div>
                  <span>工商变更函</span>
                  <i class="required-x">*</i>
                </div>
                <div class="hint-span">若无变更函，可上传征信网或启信宝变更截图</div>
              </div>
              <div
                class="field-wrapper"
              >
                <div
                  v-if="busChangeLetter.length"
                  class="cube-inputs num_color_value"
                  @click="openSheet('busChangeLetter')"
                >
                  已上传
                </div>
                <div
                  v-else
                  class="cube-inputs cube-foo num_color"
                >
                  去上传
                  <input
                    id="pic"
                    type="file"
                    name="pic"
                    accept="image/png, image/jpeg, image/jpg, application/pdf"
                    @change="changeContactUpload($event, 'busChangeLetter')"
                  >
                </div>
                <i class="icon-joyo arrows">&#xe66c;</i>
              </div>
            </cube-form-item>
            <cube-form-item :field="fields[1]">
              <div
                class="field-wrapper"
              >
                <div
                  v-if="newBusLicense.length"
                  class="cube-inputs num_color_value"
                  @click="openSheet('newBusLicense')"
                >
                  已上传
                </div>
                <div
                  v-else
                  class="cube-inputs cube-foo num_color"
                >
                  去上传
                  <input
                    id="pic"
                    type="file"
                    name="pic"
                    accept="image/png, image/jpeg, image/jpg, application/pdf"
                    @change="changeContactUpload($event, 'newBusLicense')"
                  >
                </div>
                <i class="icon-joyo arrows">&#xe66c;</i>
              </div>
            </cube-form-item>
          </cube-form-group>
        </div>
        <div
          class="bottom"
        >
          <cube-button
            type="submit"
            :disabled="!busChangeLetter.length && !newBusLicense.length"
            class="btn btnWidth"
          >
            提交
          </cube-button>
        </div>
      </cube-form>
    </div>
  </div>
</template>

<script>
import { geWxAgentConfigMaterial } from '_lib/material-weChat';

import { custModifyMyCustModify } from '@/api/cust';
import { uploadFile } from '@/api/sign';
import { isPcMobile } from '@/lib/until.js';
export default {
  data() {
    return {
      model: {
        busChangeLetterValue: '去上传',
        newBusLicenseValue: '去上传'
      },
      fields: [
        // 0
        {
          type: 'input',
          modelKey: 'busChangeLetterValue',
          label: '工商变更函',
          rules: {
            required: true
          }
        },
        // 1
        {
          type: 'input',
          modelKey: 'newBusLicenseValue',
          label: '新营业执照'
        }
      ],
      busChangeLetter: [],
      newBusLicense: []
    };
  },
  // created() {
  //   geWxAgentConfigMaterial();
  // },
  methods: {
    isPcMobileClass() {
      return isPcMobile() === 2 ? 'screenControl' : '';
    },
    openSheet(type) {
      this.$createActionSheet({
        data: isPcMobile() === 2 ? [
          {
            content: '<div>更换<input id="imgPic"  type="file"  name="pic"  accept="image/png, image/jpeg, image/jpg, application/pdf"></div>',
            class: 'cube-foo'
          }
        ] : [
          {
            content: '预览',
            class: 'cube-foo'
          },
          {
            content: '<div>更换<input id="imgPic"  type="file"  name="pic"  accept="image/png, image/jpeg, image/jpg, application/pdf"></div>',
            class: 'cube-foo'
          }
        ],
        // <a class="preView_class" href="${type === 'busChangeLetter' ? this.busChangeLetter[0].fileUrl : this.newBusLicense[0].fileUrl}"  download="${type === 'busChangeLetter' ? this.busChangeLetter[0].fileName : this.newBusLicense[0].fileName}">预览</a>
        onSelect: (item) => {
          if (item.content === '预览') {
            // wx.previewFile({
            //   url: type === 'busChangeLetter' ? this.busChangeLetter[0].fileUrl : this.newBusLicense[0].fileUrl, // 需要预览文件的地址(必填，可以使用相对路径)
            //   name: type === 'busChangeLetter' ? this.busChangeLetter[0].fileName : this.newBusLicense[0].fileName, // 需要预览文件的文件名，必须有带文件格式的后缀，例如.doc(不填的话取url的最后部分，最后部分是个包含格式后缀的文件名)
            //   size: type === 'busChangeLetter' ? this.busChangeLetter[0].fileSize : this.newBusLicense[0].fileSize, // 需要预览文件的字节大小(必填，而且大小必须正确，否则会打开失败)
            // });
            // wx.previewImage({
            //   current: type === 'busChangeLetter' ? this.busChangeLetter[0].fileUrl : this.newBusLicense[0].fileUrl, // 第一张显示的图片链接
            //   urls: [type === 'busChangeLetter' ? this.busChangeLetter[0].fileUrl : this.newBusLicense[0].fileUrl] // 需要预加载的图片http链接列表，预加载后，可以滑动浏览这些图片
            // });
            this.$createImagePreview({
              imgs: type === 'busChangeLetter' ? [this.busChangeLetter[0].fileUrl] : [this.newBusLicense[0].fileUrl]
            }).show();
          } else {
            const imgPic = document.getElementById('imgPic');
            imgPic.addEventListener('change', (e) => { this.changeContactUpload(e, type); }, false);
          }
        }
      }).show();
    },
    changeContactUpload(e, type) {
      const FILE_SIZE = 10000 * 1024 * 2;
      const fileType = e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf('.') + 1, e.target.files[0].name.length);
      const fileSize = e.target.files[0].size || 0;
      // 允许上传的文件类型
      const suffixArr = ['pdf', 'PDF', 'jpg', 'jpeg', 'JPG', 'JPEG', 'png', 'PNG'];
      if (!suffixArr.includes(fileType) || fileSize > FILE_SIZE) {
        e.target.value = '';
        this.$showToast('只支持 png/jpg/jpeg/pdf 格式，不超过20M');
        return false;
      }
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      formData.append('time', 0);
      formData.append('type', 10);
      this.$loadingG.show();
      uploadFile(formData).then(res => {
        this.$loadingG.hide();
        if (res.flag && res.data) {
          const item = [{
            fileType: fileType, // 文件类型
            fileName: res.data.record.fileName,
            fileId: res.data.record.fileId,
            fileUrl: res.data.record.fileUrl,
            fileSize: fileSize
          }];
          if (type === 'busChangeLetter') {
            this.busChangeLetter = item;
          } else {
            this.newBusLicense = item;
          }
        } else {
          this.$toast(res.errorMsg);
        }
        e.target.value = '';
      });
    },
    submitHandler(e) {
      e.returnValue = false;
      if (this.busChangeLetter.length === 0) {
        this.$showToast("工商变更函必填");
        return;
      }
      const params = {
        custId: this.$route.query.id,
        custCn: this.$route.query.custCn,
        fileId: this.busChangeLetter.length ? this.busChangeLetter[0].fileId : '',
        licenseFileId: this.newBusLicense.length ? this.newBusLicense[0].fileId : ''
      };
      custModifyMyCustModify(params).then(res => {
        if (res.flag) {
          this.$router.push(
            {
              name: 'CustChangeSucess'
            }
          );
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped src="@/assets/css/components/addstyle.scss"></style>
<style lang="scss" scoped src="@/assets/css/components/btn-bottom.scss"></style>
<style lang="scss">
.page-box-cust-change-model{
  overflow: hidden;
  background-color: $color-FFF ;
  .cube-form-label{
    flex-direction: column;
    align-items: flex-start;
    // width: 100px;
    width: auto;
    padding: 0;
    color:#23252E;
    font-size: 15px;
    text-align: left;
  }
  .cube-form-item{
    height: 56px;
    padding: 0;
  }
  .cube-form-item_required .cube-form-label::before {
    content: "";
  }
}
.cube-foo{
  color: #23252E;
  font-size: $font-16;
  line-height: 20px;
  input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 54px;
    opacity: 0;
  }
}
</style>

<style lang="scss"  scoped>
.page-title {
  margin: 48px auto;
  margin-bottom: 16px;
  color: #23252E;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}
.page-hint{
  margin-bottom: 24px;
  color: #23252E;
  font-size: 15px;
  line-height: 20px;
}

.page-input{
  margin: 0 15px 46px 15px;
}
.num_color{
  color: #C6C7CC;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
}
.num_color_value{
  color: #23252E;
  font-size: 15px;
  line-height: 20px;
  text-align: right
}
.arrows{
  margin-left: 6px;
}
.required-x{
  margin-left: 2px;
  color: #FF585C;
}
.hint-span{
  margin-top: 4px;
  color: #999BA3;
  font-size: 12px;
  line-height: 16px;
}
</style>
