import { render, staticRenderFns } from "./upload-custfile.vue?vue&type=template&id=6d64111c&scoped=true"
import script from "./upload-custfile.vue?vue&type=script&lang=js"
export * from "./upload-custfile.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/components/addstyle.scss?vue&type=style&index=0&id=6d64111c&prod&lang=scss&scoped=true&external"
import style1 from "@/assets/css/components/btn-bottom.scss?vue&type=style&index=1&id=6d64111c&prod&lang=scss&scoped=true&external"
import style2 from "./upload-custfile.vue?vue&type=style&index=2&id=6d64111c&prod&lang=scss"
import style3 from "./upload-custfile.vue?vue&type=style&index=3&id=6d64111c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d64111c",
  null
  
)

export default component.exports