import axios from './index';

/**
 * @param location 定位
 * @param keywords 搜索关键字
 * @param defaultIndex 关键字种类
 * @param searchRadius 搜索范围
 * @param defaultView  默认为 list
 * @returns {Promise<AxiosResponse<T>>}
 */
// export function getNearbyCustomer(
//   location,
//   keywords,
//   defaultIndex,
//   searchRadius = 3000,
//   defaultView = 'list'
// ) {
//   return axios.get(
//     `//m.amap.com/around/?locations=${location}&keywords=${keywords}&defaultIndex=${defaultIndex}&defaultView=&searchRadius=${searchRadius}&key=${config.MAP_KEY}`
//   );
// }

/**
 * 签到列表
 * @param aid
 * @param custCn?
 * @param registerTimeStart?
 * @param registerTimeEnd?
 * @returns {*}
 */
export const getCustRegisterList = (data, aid) =>
  axios.post('/crmapp/custregister/list', data, {
    headers: { aid }
  });

/**
 * 新增签到
 * @param data
 * @returns {*}
 */
export const saveCustRegister = data =>
  axios.post('/crmapp/custregister/save', data);

/**
 * 签到详情
 * @param id
 * @returns {*}
 */
export const getCustRegisterDetail = (id, aid) =>
  axios.post('/crmapp/custregister/detail', { id }, { headers: { aid }});

/**
 * 根据定位获取客户列表
 * @param longitude
 * @param latitude
 * @param radius
 * @param custCn
 */
export const getCustomermanagement = (longitude, latitude, radius, custCn) =>
  axios.post('/crmapp/customermanagement/aroundcust', {
    longitude,
    latitude,
    radius,
    custCn
  });

/**
 * 地址微调
 * @param longitude
 * @param latitude
 * @param radius
 * @param keywords
 */
export const getAroundAddress = (longitude, latitude, radius, keywords) =>
  axios.post('/crmapp/customermanagement/around', {
    longitude,
    latitude,
    radius,
    keywords
  });

/**
 * 所有客户列表
 * @param data
 * @param aid
 * @returns {*}
 */
export const getCustomermanagementList = data =>
  axios.post('/crmapp/customermanagement/alllist', data);

/**
 * 上传图片
 * @param data
 * @returns {*}
 */
export const uploadFile = data =>
  axios.post('/client/tencent/getFileAndFileIdFeign', data);

/**
 * 获取联系人列表
 * @param data
 * @returns {*}
 */
export const getCustContactsList = data =>
  axios.post('/crmapp/custcontacts/custContactsList', data);

/**
 * 根据地址获取经纬度信息
 * @param data
 * @returns {*}
 */
export const getGeoInfoByAddr = data =>
  axios.post('/crmapp/custregister/getGeoInfoByAddr', data);

/**
 * 修改附近客户和所有客户接口
 * @param data
 * @returns {*}
 */
export const modifyAddress = data =>
  axios.post('/crmapp/customermanagement/modifyAddress', data);
